import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Major Cloud Provider', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: 'A world\'s most dynamic cloud', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Major Cloud Provider',
  name: ' ',
  subtitle: 'A world\'s most dynamic cloud',
  cta: 'Woah',
};

// ABOUT DATA
export const aboutData = {
  img: 'creamer.jpg',
  paragraphOne: 'Efficiencies will come from effectively aligning our emerging markets. You need to dynamically invest your verticals to increase your innovation velocity. Efficiencies will come from intelligently relaying our milestones. It\'s critical that we give 110% when conservatively facilitating best practices.',
  paragraphTwo: 'We use our competitive big datas to reliably manage our executive search expectations. You need to globally transform your prince2 practitioners to increase your dot-bomb velocity. Change the way you do business - adopt seamless core competencies. Company-wide ballpark figures virtually enable innovative organic growths for our paradigm shifts.',
  paragraphThree: '',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'surpriselink.jpg',
    title: 'SurpriseLink',
    info: 'Meet SurpriseLink.  Manage your organisation\'s surprising online, with our cloud software.',
    info2: 'In the future, will you be able to ethically impact core assets in your business? We use our unparalleled market foci to strategically manage our alignment expectations. We thrive because of our next-generation standard setter and value-added low hanging fruit culture. Our brand development lifecycle enables mission critical, customer-focused visibilities.',
    url: 'http://tiffzhang.com/startup/index.html?s=995579271583',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'plugn.jpg',
    title: 'Plugn',
    info: 'We Are Plugn.  Think your niche in the plugging ecosystem with online branding that’s built by trustworthy people for mature consumers.',
    info2: 'Efficiencies will come from iteratively connecting our step-changes. In the future, will you be able to proactively integrate action points in your business? Efficiencies will come from dynamically revolutionizing our team players. So we can hit the ground running, we will be effectively virtualising every capability in our space.',
    url: 'http://tiffzhang.com/startup/index.html?s=196663632096',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Clouds are a bunch of computers!',
  btn: 'Cool',
  email: 'tony@worthybeige.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'linkedin',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'github',
    //   url: '',
    // },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
